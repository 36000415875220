export const getToken = () => {
  return window.sessionStorage.getItem("UUID_TOKEN_KEY");
};

export const saveToken = (token) => {
  window.sessionStorage.setItem("UUID_TOKEN_KEY", token);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem("UUID_TOKEN_KEY");
};

export default { getToken, saveToken, destroyToken };
