import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/student/undefined?",
      component: () => import("@/view/pages/error/Error-6.vue"),
    },
    {
      path: "/student/:token?",
      component: () => import("@/view/layout/Layout.vue"),
      // redirect: "main_form",
      children: [
        {
          path: "/file",
          name: "file",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/main_form",
              name: "main_form",
              component: () => import("@/view/pages/form"),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-3.vue"),
    },
  ],
  // routes: [
  //   {
  //     path: "/",
  //     component: () => import("@/view/pages/auth/login_pages/Login-1"),
  //     children: [
  //       {
  //         name: "login",
  //         path: "/login",
  //         component: () => import("@/view/pages/auth/login_pages/Login-1"),
  //       },
  //       {
  //         name: "register",
  //         path: "/register",
  //         component: () => import("@/view/pages/auth/login_pages/Login-1"),
  //       },
  //     ],
  //   },
  //   {
  //     path: "/",
  //     redirect: "main_form",
  //     component: () => import("@/view/layout/Layout"),
  //     children: [
  //       {
  //         path: "/file",
  //         name: "file",
  //         redirect: "main_form",
  //         component: () => import("@/view/pages/Base.vue"),
  //         children: [
  //           {
  //             path: "/main_form",
  //             name: "main_form",
  //             component: () => import("@/view/pages/form/index.vue"),
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     path: "*",
  //     redirect: "/404",
  //   },
  //   {
  //     // the 404 route, when none of the above matches
  //     path: "/404",
  //     name: "404",
  //     component: () => import("@/view/pages/error/Error-3.vue"),
  //   },
  // ],
});
