import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { StudentApiService } from "@/core/services/studentApi.service.js";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueCompositionAPI from "@vue/composition-api";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import Swal from "sweetalert2";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vuelidate";

import FormGroup from "@/view/components/FormGroup";

Vue.component("FormGroup", FormGroup);

// API service init
ApiService.init();
StudentApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueCompositionAPI);

Vue.mixin({
  data() {
    return {
      colors: [
        "indigo",
        "blue",
        "cyan",
        "blue-grey",
        "teal",
        "red",
        "green",
        "deep-purple",
        "orange",
        "pink",
        "light-green",
      ],
    };
  },
  created: function() {
    // Setting page title
    const { title } = this.$options;
    if (title) {
      const tt = typeof title === "function" ? title.call(this) : title;
      if (tt) {
        document.title = title;
      }
    }
  },
  methods: {
    toFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    // takes a {} object and returns a FormData object
    objectToFormData(obj, form, namespace) {
      let formData = form || new FormData();
      let formKey;

      for (let property in obj) {
        if (!obj.hasOwnProperty(property) && !obj[property]) {
          if (typeof obj[property] != "number") {
            continue;
          }
        }
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          formData.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], formData, formKey);
        } else {
          // if it's a string or a File object
          formData.append(formKey, obj[property]);
        }
      }

      return formData;
    },
    fireToast({ icon, title }) {
      Swal.fire({
        icon,
        title,
        toast: true,
        showConfirmButton: false,
        position: "top-end",
        timer: 4500,
        timerProgressBar: true,
      });
    },

    sweetAlertResponse({ status, header, message }) {
      const title = header;
      const icon = status ? "success" : "error";
      Swal.fire({
        title,
        text: message,
        icon,
      });
    },

    /**
     * Function that filter data through v-select filters
     * @param {*} filters Object with pairs of key-value
     * @param {*} data Data to filter
     * @returns filtered data
     */
    filterData(filters, data) {
      // Creating copy of the data without reactivity
      let dataArr = [...data];
      // Removing undefined or null attributes
      let filtersObj = Object.fromEntries(
        Object.entries(filters).filter(
          ([_, value]) => value != null || value != undefined
        )
      );
      // Iterate through filters
      Object.entries(filtersObj).forEach(([key, value]) => {
        dataArr = dataArr.filter((item) => {
          // Check if item has not the current key
          if (!item.hasOwnProperty(key)) {
            return dataArr;
          }
          // Check if filter value is an array
          if (Array.isArray(value)) {
            // Check length of the array
            if (!value.length) {
              return dataArr;
            }
            // Iterate each value of the array
            for (const iterator of value) {
              // Check if exist a coincidence
              if (item[key] == iterator) {
                return item;
              }
            }
          } else {
            return item[key] == value;
          }
        });
      });

      return dataArr;
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.fireToast({
          icon: "success",
          title: "Texto copiado en el portapapeles",
        });
      } catch ($e) {
        this.fireToast({
          icon: "error",
          title: "No ha sido posible copiar el texto",
        });
        console.error($e);
      }
    },
    existInArray(search, arr) {
      return arr.some((item) => {
        return Object.values(item).indexOf(search) >= 0;
      });
    },
    sortByProperty(property) {
      return (a, b) => {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;
        return 0;
      };
    },
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    // takes a {} object and returns a FormData object
    objectToFormData(obj, form, namespace) {
      let formData = form || new FormData();
      let formKey;

      for (let property in obj) {
        if (!obj.hasOwnProperty(property) && !obj[property]) {
          if (typeof obj[property] != "number") {
            continue;
          }
        }
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          formData.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], formData, formKey);
        } else {
          // if it's a string or a File object
          formData.append(formKey, obj[property]);
        }
      }

      return formData;
    },

    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
