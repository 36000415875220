// action types

// mutation types
export const SET_SUBJECT = "setSubject";
export const PURGE_SUBJECT = "purgeSubject";

const state = {
  subjectData: {},
};

const getters = {
  currentSubject(state) {
    return state.subjectData;
  },
};

const actions = {
  [SET_SUBJECT](state, payload) {
    state.commit(SET_SUBJECT, payload);
  },
  [PURGE_SUBJECT](state) {
    state.commit(PURGE_SUBJECT);
  },
};

const mutations = {
  [SET_SUBJECT](state, subject) {
    state.subjectData = subject;
  },
  [PURGE_SUBJECT](state) {
    state.subjectData = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
