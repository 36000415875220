import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Axios from "axios";
import { SET_PERSONAL_INFO } from "./profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const VERIFY_TOKEN = "verifyToken";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_TOKEN = "setToken";

const state = {
  errors: null,
  user: {},
  isAuthenticated: true,
  // isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          if (data.status) {
            context.commit(SET_AUTH, data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          if (!response) {
            reject({
              status: false,
              message:
                "Ha ocurrido un error al intentar conectar con el servidor 🙁",
            });
          }
          if (response.status === 401) {
            reject(response.data);
          }
          // console.error("Here what post returns", response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise((resolve) => {
        ApiService.get("auth", "logout").then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  // [REGISTER](context, credentials) {
  //   return new Promise((resolve) => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },
  [VERIFY_TOKEN](context, token) {
    return new Promise((resolve, reject) => {
      // Make GET Request for retrieving data with token
      Axios.get(`${process.env.API_URL}/students/information`, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(({ data }) => {
          // Check if response return failed message
          if (data == null) {
            // Return failed response message

            reject({
              status: data.response,
              message:
                "No es posible ingresar al sistema, asegurate de iniciar sesión desde el portal estudiantil y luego acceder a la aplicación",
            });
          } else {
            // Setting user and token
            context.commit(SET_AUTH, data);
            context.commit(SET_TOKEN, data.uuid);
            resolve();
          }
        })
        .catch((err) => {
          reject();
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(`${process.env.API_URL}/students/information`)
        .then(({ data }) => {
          let authData = {
            user: data.id,
            authData: JwtService.getToken(),
          };
          // console.log("despues de la petición", authData.authData);
          // context.commit(SET_AUTH, authData);
          context.commit(SET_PERSONAL_INFO, data);
        })
        .catch((error) => {
          console.error(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, authData) {
    state.isAuthenticated = true;
    state.user = authData.id;
    state.errors = {};
    JwtService.saveToken(authData.uuid);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [SET_TOKEN](state, token) {
    JwtService.saveToken(token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
